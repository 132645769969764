const timeRange = {
  fromBiggerThanTo: 'Le temps de fin ne peut pas être avant le temps de début',
  isOverlapping: 'Cette plage horaire chevauche',
  isOverlapping_time: 'Cette plage horaire chevauche: {{ type }} à {{ time }}',
  mustEndInFifteenMinutesIncrement: 'Le temps doit finir en incrément de 15 minutes',
  mustMatchDuration: 'La somme de toutes les heures doit être égale à la durée',
  noTimeRangeOverlap: 'Les intervalles ne peuvent pas se chevaucher',
};

const timePeriodType = {
  serviceHour: 'Service de grue',
  lunchBreak: 'Pause repas',
  mechanicalFailure: 'Bris mécanique',
  manTime: "Temps d'homme",
  manTransport: "Transport d'homme",
  noBreakTime: 'No Break',
  noLunchTime: 'No Lunch',
};

export const schemas = {
  timeRange,
  invalidBranch: 'Le numéro de succursale est invalide',
  invalidWorkType: 'La nature du travail est invalide',
  invalidWorkKind: 'Le genre du travail invalide',
  invalidGtNumber: 'Le numéro de GT est invalide',
  invalidNoLunchTimeRange: 'Un intervalle de type "no lunch break" doit avoir une durée de 30 minutes',
  invalidNoBreakTimeRange: 'Un intervalle de type "no break time" doit avoir une durée de 15 minutes',
  invalidPreparationTimeRange: 'Un intervalle de type "préparation" doit avoir une durée de 1 ou 1.25 heure',
  invalidCodeOrSubcode: 'La combination de code et sous-code est invalide',
  invalidLabourCode: "Le code de main d'oeuvre est invalide",
  noCodeDeFacturationInDouble: 'Ce code de facturation existe déjà.',
  totalCraneTimeError:
    'Les heures de la carte de temps doivent être inférieures ou égales aux heures de service de grue du contrat de service',
  required: 'Requis',
  indemnitySplitRequiredOneEmployee: 'Au moins un employé doit être sélectionné',
  singleFeeIndemnityRequiredAtLeastCountOfOne: 'Au moins un frais doit être appliqué à un employé',
  justificationRequired: 'Une justification est nécessaire quand le taux est modifié',
  justificationPrevented: 'Aucune justification ne peux être entrée quand le taux reste le même',
  requireSelection: 'Au moins un employé doit être sélectionné',
  requireCount: 'Au moins un employé doit avoir une valeur différente de 0',
  requirePensionType: 'Le type de pension est requis',
  minimumQuantityRequired: 'La quantité minimum requise est de {{ count }}',
  minimumHourRequired_one: "Le nombre d'heure minimum requis est de {{ count }}",
  minimumHourRequired_other: "Le nombre d'heures minimum requis est de {{ count }}",
  timePeriodType,
  invalidAccessoryGroupId: "L'accesoire est invalide",
  invalidAccessoryDescription: "L'accesoire nécessite une description manuelle",
  invalidPosteDeTravailShiftOverlap: "Chevauchement d'horaire",
  invalidPosteDeTravailDayShift: 'Horaire de jour',
  invalidPosteDeTravailNightShift: 'Horaire de soir',
  invalidWorkTypeForBaieJamesAdditionalDigit: 'Poste de travail Baie-James non disponible pour cette Nature',
  invalidWorkKindForBaieJamesAdditionalDigit: 'Poste de travail Baie-James non disponible pour ce Genre',
};
